import React from 'react';
import SEO from '../components/seo';
import Layout from '../components/Layout';
import ContactForm from '../components/ContactForm';

function FeedbackPage() {
  return (
    <>
      <SEO title="Tilføj begivenhed" />
      <Layout>
        <section className="explore-container">
          <h1>Udfyld formular og tilføj begivenhed</h1>
          <p>
            Har I et event I ønsker at dele på Zooticket?
            <br />
            Udfyld formularen herunder med navn på begivenhed, dato, postnummer
            og link til begivenheden.
            <br />
            Vi tilføjer begivenheden indenfor 48 timer.
          </p>
          <ContactForm addEvent={true} />
        </section>
      </Layout>
    </>
  );
}

export default FeedbackPage;
